import React, { useState } from "react";
import "../css/loginform.css";
import sclm from "../images/sclm.png";
import { Link,useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";


const Loginform = () => {
  const navigate = useNavigate();
  const backgroundStyle = {
    backgroundImage: `url(${sclm})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "auto",
    padding: "70px 0 60px 0",
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectAccount, setSelectAccount] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    email: {
      value: "",
      error: false,
      errorMessage: "Email is required",
    },
    password: {
      value: "",
      error: false,
      errorMessage: "Password is required",
    },
    selectAccount: {
      value: "",
      error: false,
      errorMessage: "Select Account is required",
    },
  });

  const handleChangeEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      email: {
        ...prevErrors.email,
        value: value,
        error: false,
      },
    }));
  };

  const handleChangePassword = (event) => {
    const value = DOMPurify.sanitize(event.target.value);
    setPassword(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      password: {
        ...prevErrors.password,
        value: value,
        error: false,
      },
    }));
  };

  const handleChangeAccount = (event) => {
    const value = event.target.value;
    setSelectAccount(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      selectAccount: {
        ...prevErrors.selectAccount,
        value: value,
        error: false,
      },
    }));
  };

  const login = async () => {
    let hasError = false;

    if (!email.trim()) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        email: {
          ...prevErrors.email,
          error: true,
          errorMessage: "Email is required",
        },
      }));
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        email: {
          ...prevErrors.email,
          error: true,
          errorMessage: "Email is not valid",
        },
      }));
      hasError = true;
    }

    if (!password.trim()) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        password: {
          ...prevErrors.password,
          error: true,
        },
      }));
      hasError = true;
    }

    if (!selectAccount) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        selectAccount: {
          ...prevErrors.selectAccount,
          error: true,
        },
      }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const loginData = {
      email: email,
      password: password,
      selectAccount: selectAccount,
    };
    try {
      await axios
        .post("http://localhost:9000/login", loginData)
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            const userData = {
              Accountid: data.data.data.id,
              username: data.data.data.username,
              usertype: data.data.data.usertype
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            toast.success(data.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "toast-error",
            });
          } else if (err.response && err.response.status === 300) {
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "toast-error",
            });
            setTimeout(()=>{
              navigate("/Newforgotemail");

            },5000)
          }else if (err.response && err.response.status === 500) {
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "toast-error",
            });
          } else {
            toast.error("An error occurred. Please try again later.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: "toast-error",
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <ToastContainer />
      <div style={backgroundStyle}>
        <div className="container">
          <div className="form-user" id="mx-wdth-lgn">
            <h3>Login</h3>
            <form>
              <input
                type="text"
                className={
                  fieldErrors.email.error
                    ? "form-control error"
                    : "form-control"
                }
                placeholder="Email"
                value={email}
                onChange={handleChangeEmail}
              />
              {fieldErrors.email.error && (
                <p className="error-message">
                  {fieldErrors.email.errorMessage}
                </p>
              )}
              <input
                type="password"
                className={
                  fieldErrors.password.error
                    ? "form-control pswrd error"
                    : "form-control pswrd"
                }
                placeholder="Password"
                value={password}
                onChange={handleChangePassword}
              />
              {fieldErrors.password.error && (
                <p className="error-message">
                  {fieldErrors.password.errorMessage}
                </p>
              )}
              <Box className="slct" sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Account
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", margin: 0 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectAccount}
                    label="Select Account"
                    onChange={handleChangeAccount}
                  >
                    <MenuItem value={1}>User</MenuItem>
                    <MenuItem value={2}>Affiliate</MenuItem>
                    <MenuItem value={3}>Vendor</MenuItem>
                    <MenuItem value={4}>Advertiser</MenuItem>
                    <MenuItem value={5}>Instructor</MenuItem>
                    <MenuItem value={6}>Blogger</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {fieldErrors.selectAccount.error && (
                <p className="error-message">
                  {fieldErrors.selectAccount.errorMessage}
                </p>
              )}
              <div className="signupbtn" style={{textAlign:"center"}}>
                <button type="button" onClick={login}>
                  Sign in
                </button>
              </div>
              <div className="signupbtn" style={{textAlign:"center"}}>
              <Link to="/Forgoten">Forgot Password /</Link>
             
              <Link to="/Forgotemail">Forgot Email</Link>
              </div>
             
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginform;
