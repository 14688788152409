import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import EnableAccount from '../components/EnableAccount';

const Forgoten = () => {
    return (
        <div>
            <Header />
            <EnableAccount />
            <Footer />
        </div>
    );
};

export default Forgoten;
