import React, { Component } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import rest3 from'../images/rest3.webp';
import rest4 from'../images/rest4.webp';
import txi from'../images/txi.jpg';
import rest5 from'../images/rest5.jpg';
import rest from'../images/rest.webp';
import rest1 from'../images/rest1.avif';
import rest2 from'../images/rest2.jpg';
import '../css/slider.css';

class CustomSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 600, // Mobile view breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    };

    return (
        <div className="container">
            <div className="slide-catgry">
                <Slider {...settings}>
                    <div>
                        <img src={rest3} alt="slider" />
                        <h3>Restaurants</h3>
                    </div>
                    <div>
                        <img src={txi} alt="taxi" />
                        <h3>Car Taxi Hires</h3>
                    </div>
                    <div>
                        <img src={rest5} alt="ari" />
                        <h3>Restaurants</h3>
                    </div>
                    <div>
                        <img src={rest} alt="slider" />
                        <h3>Restaurants</h3>
                    </div>
                    <div>
                        <img src={rest1} alt="slider" />
                        <h3>Restaurants</h3>
                    </div>
                    <div>
                    <img src={rest2} alt="slider" />
                    <h3>Slide 3</h3>
                    </div>
                    <div>
                        <img src={rest4} alt="slider" />
                        <h3>Restaurants</h3>
                    </div>
                </Slider>
            </div>    
        </div>    
    );
  }
}

export default CustomSlider;
