import React from 'react';
import '../css/Information.css';
import scll from '../images/scll.png';
import info from '../images/info.png';
import bloggg from '../images/bloggg.png';
import profile from '../images/profile.jpg';
import profile1 from '../images/profile1.jpg';
import profile2 from '../images/profile2.jpeg';
import profile3 from '../images/profile3.jpg';
import profile4 from '../images/profile4.png';
import profile5 from '../images/profile5.jpg';
import profile6 from '../images/profile6.jpg';
import profile7 from '../images/profile7.jpg';
import profile8 from '../images/profile8.jpeg';
import { Link } from 'react-router-dom';
const Informationac = () => {
    return (
        <div>
            <div className="banner" id="info-banner">
            <div className="container">
                <div className="banner-text">
                    <h3>Information</h3>
                </div>
            </div>
            <img src={info} alt="info" />
          </div>	
            <div className="container">
                <div className="accounts-section">
                    <h3>User Accounts</h3>
                    <div className="accounts-profile">
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                         <div className="accounts">
                            <img src={bloggg} alt="bloggg" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="sprofile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={scll} alt="scll" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        
                    </div>  
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>  
                </div>    
                <div className="accounts-section">
                    <h3>Blogger Accounts</h3>
                    <div className="accounts-profile">
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                         <div className="accounts">
                            <img src={bloggg} alt="bloggg" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="sprofile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={scll} alt="scll" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        
                    </div>  
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>    
                </div>
                <div className="accounts-section">
                    <h3>Vendor Accounts</h3>
                    <div className="accounts-profile">
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                         <div className="accounts">
                            <img src={bloggg} alt="bloggg" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="sprofile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={scll} alt="scll" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        
                    </div>  
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>
                </div>
                <div className="accounts-section">
                    <h3>Affiliate Accounts</h3>
                    <div className="accounts-profile">
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                         <div className="accounts">
                            <img src={bloggg} alt="bloggg" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="sprofile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={scll} alt="scll" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        
                    </div> 
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>   
                </div>
                
                <div className="accounts-section">
                    <h3>Instructor Accounts</h3>
                    <div className="accounts-profile">
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                         <div className="accounts">
                            <img src={bloggg} alt="bloggg" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="sprofile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={scll} alt="scll" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        
                    </div>    
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>
                </div>
                <div className="accounts-section">
                    <h3>Advertiser Accounts</h3>
                    <div className="accounts-profile">
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                         <div className="accounts">
                            <img src={bloggg} alt="bloggg" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="sprofile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={scll} alt="scll" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile1} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile2} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile3} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile4} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile5} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile6} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile7} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        <div className="accounts">
                            <img src={profile8} alt="profile" />
                            <p>Name</p>
                            <Link to="" className="lnks">View Profile</Link>
                        </div>
                        
                    </div>    
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>
                </div>
                <div className="accounts-section">
                    <h3>Special Events</h3>
                    <div className="accounts-profile">
                        <div className="accounts" id="events">
                            <img src={profile} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile1} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile2} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile3} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile4} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile5} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile6} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile7} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile8} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={bloggg} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={scll} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile1} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile2} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile3} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile4} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile5} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile6} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile7} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile8} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        
                    </div>    
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>
                </div>
                <div className="accounts-section">
                    <h3>Latest News</h3>
                    <div className="accounts-profile">
                        <div className="accounts" id="news">
                            <img src={profile} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile1} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>    
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile2} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile3} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>  
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile4} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>  
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile5} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>    
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile6} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile7} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile8} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>  
                        </div>
                        <div className="accounts" id="news">
                            <img src={bloggg} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p> 
                        </div>
                        <div className="accounts" id="news">
                            <img src={scll} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile1} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>  
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile2} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile3} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>  
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile4} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile5} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>  
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile6} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile7} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>   
                        </div>
                        <div className="accounts" id="news">
                            <img src={profile8} alt="profile" />
                            <span>Personal Adviser</span>
                            <h4>Computer and Using Some Color</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad 
                            possimus magm maiores....</p>    
                        </div>
                        
                    </div>    
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>
                </div>
                <div className="accounts-section">
                    <h3>Special Offers</h3>
                    <div className="accounts-profile">
                        <div className="accounts" id="events">
                            <img src={profile} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile1} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile2} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile3} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile4} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile5} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile6} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile7} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile8} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={bloggg} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={scll} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile1} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile2} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile3} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile4} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile5} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile6} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile7} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        <div className="accounts" id="events">
                            <img src={profile8} alt="profile" />
                            <div class="wht-wed">
                                <h6>What an Event Attendee Wants</h6>
                                <p>Wednesday, February 21, 2024 at 2pm ET / 11am PT</p>
                            </div>    
                        </div>
                        
                    </div>    
                    <div class="view-more-accounts">
                        <Link to="">View More</Link>  
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default Informationac;