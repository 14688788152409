import React,{useContext} from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Blogersignupform from '../components/Blogersignupform.js';
import Questions6 from '../components/QuestionsStep6.js';
import { Stepper,StepLabel,Step } from '@material-ui/core';
import { MultiStepContext } from '../StepContext6.js';
import sclm from '../images/sclm.png';
const Blogersignup = () => {
    const {currentStep}= useContext(MultiStepContext);
  function showStep(step){
    switch(step){
      case 1:
        return <Blogersignupform/>
      case 2:
        return <Questions6/>
      default:
        return <Blogersignupform/>

    }
  }
  const backgroundStyle = {
    backgroundImage: `url(${sclm})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 'auto', 
    padding: '70px 0 60px 0',
};
    return(
        <div>
            <Header />
            <div className="App" >
            <div style={backgroundStyle}>
              <div className="container">
                <div className="form-user">
                    <header className="App-header">
                        <div>
                          <h3>Bloger SIGNUP</h3>
                          <div className="stepsare">
                            <Stepper style={{witdth:'18%'}} activeStep={currentStep -1} >
                              
                                <Step>
                                    <StepLabel>Step 1</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Step 2</StepLabel>
                                </Step>
                              
                            </Stepper>
                          </div>  
                        </div>
                        {showStep(currentStep)}
                        

                      
                        
                    </header>
                  </div>  
                </div>    
            </div>
            </div>
         
            <Footer />
        </div>
    )
}

export default Blogersignup;



