import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import '../css/profile.css'; // Ensure this path is correct
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import blg from '../images/blg.jpg';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function Edituserprofile() {
    // State to manage the selected age
    const [age, setAge] = useState('');

    // Handler for changing the selected age
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1>Edit User Profile</h1>
                </Grid>
            </Grid>
            <div className="container">
                <div className="usr-dhbrd">
                <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div className="leftside">
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <img src={blg} alt="blg" />
                                        </Grid>   
                                        <Grid item xs={7}>
                                            <h3>rohit kumar</h3>
                                            <p>ID: User22</p>
                                        </Grid>   
                                    </Grid>   
                                    <ul>
                                        <li><i class="fa fa-home" aria-hidden="true"></i> Visit</li>
                                        <li>
                                        <Accordion
                                            sx={{ padding: 0, boxShadow: 'unset', border: 'unset' }}
                                        >
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            sx={{ padding: 0, boxShadow: 'unset', border: 'unset' }}
                                            >
                                            <i class="fa fa-home" aria-hidden="true"></i> Dashboard
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ul className="edt-prfl-pswrd">
                                                    <li>Edit Profile</li>
                                                    <li>Password</li>
                                                </ul>
                                            </AccordionDetails>
                                        </Accordion>
      
      
                                        </li>
                                        <li><i class="fa fa-cogs" aria-hidden="true"></i> General</li>
                                        <li><i class="fa fa-outdent" aria-hidden="true"></i>My Order</li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i> Review</li>
                                        <li><i class="fa fa-comments" aria-hidden="true"></i> Chat</li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                            <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Male</MenuItem>
                                            <MenuItem value={20}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                </Grid>
                                <Grid item xs={4}>
                                <TextField sx={{width:'100%'}}id="outlined-basic" label="Company Name*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                <TextField sx={{width:'100%'}}id="outlined-basic" label="Name*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                <TextField sx={{width:'100%'}}id="outlined-basic" label="Surname*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                <TextField sx={{width:'100%'}}id="outlined-basic" label="E-mail" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                <TextField sx={{width:'100%'}}id="outlined-basic" label="Phone number *" variant="outlined" />
                               </Grid>
                               <Grid item xs={4}>
                                <TextField sx={{width:'100%'}}id="outlined-basic" label="Address *" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                <TextField sx={{width:'100%'}}id="outlined-basic" label="Zip Code *" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                <Box sx={{ width: '100%' }}>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        id="date"
                                        label="Select Date *"
                                        type="date"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                </Grid>
                                <Grid item xs={4}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Male</MenuItem>
                                            <MenuItem value={20}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                </Grid>
                                <Grid item xs={4}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Male</MenuItem>
                                            <MenuItem value={20}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                </Grid>
                                <Grid item xs={4}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">City</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Male</MenuItem>
                                            <MenuItem value={20}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                </Grid>

                                <Grid item xs={4}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">sex *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Male</MenuItem>
                                            <MenuItem value={20}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                </Grid>
                                <Grid item xs={4}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Hair Colour *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Male</MenuItem>
                                            <MenuItem value={20}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                </Grid>
                                <Grid item xs={4}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Eye Colour *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Male</MenuItem>
                                            <MenuItem value={20}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Body Type *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={age}
                                                label="Age"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Male</MenuItem>
                                                <MenuItem value={20}>Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="Weight (cm) *" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="Height (cm) *" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">smoker*</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={age}
                                                label="Age"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Male</MenuItem>
                                                <MenuItem value={20}>Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Accepts Polygamy*</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={age}
                                                label="Age"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Male</MenuItem>
                                                <MenuItem value={20}>Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="Fax Number*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="whatsapp*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="Viber*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="Skype Id*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="Facebook Id*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="lnstagram Id*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="twitter Id*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="tumblr Id*" variant="outlined" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField sx={{width:'100%'}}id="outlined-basic" label="wechat Id*" variant="outlined" />
                                </Grid>
                            </Grid>  
                            <h3>RELATIONSHIPS*</h3>  
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Affair" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Open Relationships" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Couple Relationship" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Hanging Out" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Friends" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Chats/Mail" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Travel" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Dinner Date" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Other Activities" />
                                </Grid>
                            </Grid>
                    </Grid>
                    </Grid>
                </div>
            </div>       
        </div>
    );
}

export default Edituserprofile;
