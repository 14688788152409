import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Informationac from '../components/Informationaccount.js'

const Information = () => {
    return(
        <div>

            <Header />
            <Informationac />
            <Footer />

        </div>
    )
}

export default Information;