import React, { useState } from "react";
import sclm from "../images/sclm.png";
import "../css/signupform.css";
import TextField from "@mui/material/TextField";
import axios from "axios"; // Import Axios for HTTP requests
import { ToastContainer, toast } from "react-toastify";
const Forgotemail = () => {
    const [qus, setQus] = useState(""); // State for primary school name
    const [qus1, setQus1] = useState(""); // State for favorite food
    const [qus2, setQus2] = useState(""); // State for best friend's name
    const [user, setUser] = useState(""); // State for username or account number
    const [errors, setErrors] = useState({}); // State for field errors

    // Handler for primary school name change
    const handleQusChange = (event) => {
        setQus(event.target.value); // Update state with user input
        setErrors({ ...errors, qus: "" }); // Hide error for primary school name
    };
    
    // Handler for favorite food change
    const handleQus1Change = (event) => {
        setQus1(event.target.value); // Update state with user input
        setErrors({ ...errors, qus1: "" }); // Hide error for favorite food
    };
    
    // Handler for best friend's name change
    const handleQus2Change = (event) => {
        setQus2(event.target.value); // Update state with user input
        setErrors({ ...errors, qus2: "" }); // Hide error for best friend's name
    };

    // Handler for username or account number change
    const handleUserChange = (event) => {
        setUser(event.target.value); // Update state with user input
        setErrors({ ...errors, user: "" }); // Hide error for username or account number
    };

    // Handler for form submission
    const handleSubmit = async () => {
        // Validate if all fields are filled
        const newErrors = {};
        if (!qus) {
            newErrors.qus = "Primary School Name is required.";
        }
        if (!qus1) {
            newErrors.qus1 = "Favorite Food is required.";
        }
        if (!qus2) {
            newErrors.qus2 = "Best Friend's Name is required.";
        }
        if (!user) {
            newErrors.user = "Username or Account Number is required.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const response = await axios.post("http://localhost:9000/change-email", {
                user: user,
                qus: qus,
                qus1: qus1,
                qus2: qus2
            });
        
            console.log(response.data); 
        
            // Check if a redirectURL is provided in the response
            if (response.data.redirectURL) {
                window.location.href = response.data.redirectURL;
            } else {
                console.error("Error: No redirectURL provided in the response");
            }
        } catch (error) {
            // Handle network errors
            console.error("Network error:", error.message);
            // Handle response errors
            if (error.response) {
                if (error.response.status === 500) {
                    toast.error(error.response.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        className: "toast-error",
                      });
                } else if (error.response.status === 501) {
                    toast.error(error.response.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        className: "toast-error",
                      });
                } else if (error.response.status === 401) {
                     
                     toast.error(error.response.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        className: "toast-error",
                      });
                    // Handle answer doesn't match error
                }
                else {
                    console.error("Unknown error:", error.response.data.error);
                    // Handle other errors
                }
            }
        }
    };

    return (
        <div>
             <ToastContainer />
            <div className="pass-fr" style={{backgroundImage: `url(${sclm})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: 'auto', padding: '70px 0 60px 0'}}>
                <div className="container">
                    <div className="form-user" id="mx-wdth-lgn">
                        <h3>forgot Email</h3>
                        <h4 style={{textAlign:'center'}}>Security Questions</h4>
                        <TextField
                            style={{ width: "100%"}}
                            label="User Name or Account number"
                            type="text"
                            margin="normal"
                            variant="outlined"
                            value={user} // Bind value to state
                            onChange={handleUserChange} // Handle change in input
                            error={errors.user ? true : false} // Set error state for this field
                            helperText={errors.user} // Display error message
                        />
                        <TextField
                            style={{ width: "100%"}}
                            label="Your Primary School Name"
                            type="text"
                            margin="normal"
                            variant="outlined"
                            value={qus} // Bind value to state
                            onChange={handleQusChange} // Handle change in input
                            error={errors.qus ? true : false} // Set error state for this field
                            helperText={errors.qus} // Display error message
                        />
                        <TextField
                            style={{ width: "100%"}}
                            label="Your Favourite Food"
                            type="text"
                            margin="normal"
                            variant="outlined"
                            value={qus1} // Bind value to state
                            onChange={handleQus1Change} // Handle change in input
                            error={errors.qus1 ? true : false} // Set error state for this field
                            helperText={errors.qus1} // Display error message
                        />
                        <TextField
                            style={{ width: "100%"}}
                            label="Your Best Friend Name"
                            type="text"
                            margin="normal"
                            variant="outlined"
                            value={qus2} // Bind value to state
                            onChange={handleQus2Change} // Handle change in input
                            error={errors.qus2 ? true : false} // Set error state for this field
                            helperText={errors.qus2} // Display error message
                        />
                        <div className="sndbtn">
                            <button type="button" onClick={handleSubmit}>Send</button>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    );
};

export default Forgotemail;
