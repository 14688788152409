import React, { Component } from 'react';  
import off from'../images/off.png';
import '../css/social.css';
class Offers extends Component {  


    render() {
      return (
        <div className="">
            <div className="percntg-off">
                <div className="container">
                    <div className="spcl-ofr">
                        <p>This week’s special offer</p>
                        <h3>GET 15% off</h3>
                        <a className="" href="#">Book now</a>
                    </div>
                </div>
                <img src={off} alt="off" />
            </div>
        </div>     
      );
      
    }
  }
  







export default Offers;