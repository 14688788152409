import React, { useContext, useState, useEffect } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { MultiStepContext } from "../StepContext6";
import '../css/Instructorsignupform.css'
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
export default function Blogersignupform() {
  const { setStep, blogerData, setBlogerData } = useContext(MultiStepContext);
  const [selectCountry, setSelectCountry] = useState("");
  const [selectState, setSelectState] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [selectCategories, setSelectCategories] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    category: {
      value: "",
      error: false,
      errorMessage: "Category is required",
    },
    company: {
      value: "",
      error: false,
      errorMessage: "Company is required",
    },
    firstname: {
      value: "",
      error: false,
      errorMessage: "First Name is required",
    },
    lastname: {
      value: "",
      error: false,
      errorMessage: "Last Name is required",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "Email is required",
    },
    phone: {
      value: "",
      error: false,
      errorMessage: "Phone is required",
    },
    country: {
      value: "",
      error: false,
      errorMessage: "Country is required",
    },
    state: {
      value: "",
      error: false,
      errorMessage: "State is required",
    },
    city: {
      value: "",
      error: false,
      errorMessage: "City is required",
    },
    username: {
      value: "",
      error: false,
      errorMessage: "Username is required",
  },
    address: {
      value: "",
      error: false,
      errorMessage: "Address is required",
    },
    password: {
      value: "",
      error: false,
      errorMessage: "Password is required",
    },
    cpassword: {
      value: "",
      error: false,
      errorMessage: "Confirm Password is required",
    },
    checkbx: {
      value: "",
      error: false,
      errorMessage: "Accept Terms & Conditions ",
    },
  });

  function validateForm() {
    let formIsValid = true;

    // Check for empty fields
    Object.keys(fieldErrors).forEach((key) => {
      if (!blogerData[key]) {
        setFieldErrors((prevFieldErrors) => ({
          ...prevFieldErrors,
          [key]: {
            ...prevFieldErrors[key],
            error: true,
          },
        }));
        formIsValid = false;
      }
    });

    // Check password match
    if (blogerData.password !== blogerData.cpassword) {
      setFieldErrors((prevFieldErrors) => ({
        ...prevFieldErrors,
        cpassword: {
          ...prevFieldErrors.cpassword,
          error: true,
          errorMessage: "Passwords do not match",
        },
      }));
      formIsValid = false;
    }

    if (!/\S+@\S+\.\S+/.test(blogerData.email)) {
      setFieldErrors((prevFieldErrors) => ({
        ...prevFieldErrors,
        email: {
          ...prevFieldErrors.email,
          error: true,
          errorMessage: "Email is not valid",
        },
      }));
      formIsValid = false;
    }

    return formIsValid;
  }

  function handleNextStep() {
    if (validateForm()) {
      setStep(2);
    }
  }

  function handleChange(event, key) {
    const { value } = event.target;
    setBlogerData((prevUserData) => ({
      ...prevUserData,
      [key]: value,
    }));
    setFieldErrors((prevFieldErrors) => ({
      ...prevFieldErrors,
      [key]: {
        ...prevFieldErrors[key],
        error: false,
      },
    }));
  }

  const [countries, setCountries] = useState([]);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);

  // fetch categories

  useEffect(() => {
    axios
      .get("http://localhost:9000/getCategory")
      .then((response) => {
        const categoriesData = response.data.map((category) => ({
          value: category.id,
          label: category.category,
        }));
        setCategories(categoriesData);
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
      });
  }, []);

  // fetch country data

  useEffect(() => {
    axios
      .get("http://localhost:9000/country")
      .then((response) => {
        const countryData = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountries(countryData);
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
      });
  }, []);

  // select acc
  const handleChangeCountry = (event) => {
    const value = event.target.value;
    setSelectCountry(value);

    axios
      .post("http://localhost:9000/state", { country: value })
      .then((response) => {
        const stateData = response.data.map((state) => ({
          value: state.id,
          label: state.name,
        }));
        setState(stateData);
        setBlogerData((prevUserData) => ({
          ...prevUserData,
          country: value, // Add selected country to other_userData
          state: "", // Reset selected state
          city: "", // Reset selected city
        }));
      })
      .catch((error) => {
        console.error("Error fetching state data:", error);
      });
  };

  // state
  const handleChangeState = (event) => {
    const value = event.target.value;
    setSelectState(value);

    axios
      .post("http://localhost:9000/cities", { state: value })
      .then((response) => {
        const cityData = response.data.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setCities(cityData);
        setBlogerData((prevUserData) => ({
          ...prevUserData,
          state: value, // Reset selected state
          city: "", // Reset selected city
        }));
      })
      .catch((error) => {
        console.error("Error fetching state data:", error);
      });
  };

  // city
  const handleChangeCity = (event) => {
    const value = event.target.value;
    setSelectCity(value);
    setBlogerData((prevUserData) => ({
      ...prevUserData,
      city: value, // Reset selected city
    }));
  };

  //category
  const handleChangeCategory = (event) => {
    const value = event.target.value;
    setSelectCategories(value);
    setBlogerData((prevUserData) => ({
      ...prevUserData,
      category: value, // Reset selected city
    }));
  };

  return (
    <div>
      <div className="flx-inputt">
        <div style={{ width: "48%" }}>
          <Box className="slct" sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel sx={{ top: 15 }} id="demo-simple-select-labels">
                Select Category *
              </InputLabel>
              <Select
                sx={{ width: "100%", margin: "16px 0" }}
                labelId="demo-simple-select-labels"
                id="demo-simple-select"
                value={selectCategories}
                label="SelectCategories"
                onChange={handleChangeCategory}
                error={fieldErrors.category.error}
              >
                {categories.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {fieldErrors.category.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.category.errorMessage}
            </Typography>
          )}
        </div>
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="Company Name *"
            margin="normal"
            value={blogerData.company}
            onChange={(e) => handleChange(e, "company")}
            variant="outlined"
            error={fieldErrors.company.error}
          />
          <br />
          {fieldErrors.company.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.company.errorMessage}
            </Typography>
          )}
        </div>
      </div>
      <div className="flx-inputt">
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="First Name"
            margin="normal"
            value={blogerData.firstname}
            onChange={(e) => handleChange(e, "firstname")}
            variant="outlined"
            error={fieldErrors.firstname.error}
          />
          <br />
          {fieldErrors.firstname.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.firstname.errorMessage}
            </Typography>
          )}
        </div>
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="Last Name"
            margin="normal"
            value={blogerData.lastname}
            onChange={(e) => handleChange(e, "lastname")}
            variant="outlined"
            error={fieldErrors.lastname.error}
          />
          <br />
          {fieldErrors.lastname.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.lastname.errorMessage}
            </Typography>
          )}
        </div>
      </div>
      <div className="flx-inputt">
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="User Name"
            margin="normal"
            value={blogerData.username}
            onChange={(e) => handleChange(e, "username")}
            variant="outlined"
            error={fieldErrors.username.error}
          />
          {fieldErrors.username.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.username.errorMessage}
            </Typography>
          )}
        </div>
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="Address"
            margin="normal"
            value={blogerData.address}
            onChange={(e) => handleChange(e, "address")}
            variant="outlined"
            error={fieldErrors.address.error}
          />
          {fieldErrors.address.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.address.errorMessage}
            </Typography>
          )}
        </div>
      </div>
      <div className="flx-inputt">
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="Email"
            margin="normal"
            value={blogerData.email}
            onChange={(e) => handleChange(e, "email")}
            variant="outlined"
            error={fieldErrors.email.error}
          />
          <br />
          {fieldErrors.email.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.email.errorMessage}
            </Typography>
          )}
        </div>
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="Phone"
            margin="normal"
            value={blogerData.phone}
            onChange={(e) => handleChange(e, "phone")}
            variant="outlined"
            error={fieldErrors.phone.error}
          />
          <br />
          {fieldErrors.phone.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.phone.errorMessage}
            </Typography>
          )}
        </div>
      </div>
      <div className="flx-adrs slct-box">
        <Box className="slct" sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Country *
            </InputLabel>
            <Select
              sx={{ width: "100%", margin: 0 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectCountry}
              label="Select Country"
              onChange={handleChangeCountry}
              error={fieldErrors.country.error}
            >
              {countries.map((country) => (
                <MenuItem key={country.value} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {fieldErrors.country.error && (
          <Typography variant="body2" color="error">
            {fieldErrors.country.errorMessage}
          </Typography>
        )}
      </div>

      <div className="flx-inputt" id="slct-box">
        <div style={{ width: "48%" }}>
          {selectCountry ? (
            <Box className="slct" sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select State *
                </InputLabel>
                <Select
                  sx={{ width: "100%", margin: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select statevalue"
                  value={selectState}
                  label="Select State"
                  onChange={handleChangeState}
                  error={fieldErrors.state.error}
                >
                  {states.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <Box className="slct" sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select State *
                </InputLabel>
                <Select
                  sx={{ width: "100%", margin: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select statevalue"
                  value={selectState}
                  label="Select State"
                  onChange={handleChangeState}
                  error={fieldErrors.state.error}
                ></Select>
              </FormControl>
            </Box>
          )}
          {fieldErrors.state.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.state.errorMessage}
            </Typography>
          )}
        </div>
        <div style={{ width: "48%" }}>
          {selectState ? (
            <Box className="slct" sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select City
                </InputLabel>
                <Select
                  sx={{ width: "100%", margin: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectCity}
                  label="Select City"
                  onChange={handleChangeCity}
                >
                  {cities.map((city) => (
                    <MenuItem key={city.value} value={city.value}>
                      {city.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <Box className="slct" sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select City
                </InputLabel>
                <Select
                  sx={{ width: "100%", margin: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectCity}
                  label="Select City"
                  onChange={handleChangeCity}
                  error={fieldErrors.city.error}
                ></Select>
              </FormControl>
            </Box>
          )}
          {fieldErrors.city.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.city.errorMessage}
            </Typography>
          )}
        </div>
      </div>
      <div className="flx-inputt">
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="Password"
            type="password"
            margin="normal"
            value={blogerData.password}
            onChange={(e) => handleChange(e, "password")}
            variant="outlined"
            error={fieldErrors.password.error}
          />
          <br />
          {fieldErrors.password.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.password.errorMessage}
            </Typography>
          )}
        </div>
        <div style={{ width: "48%" }}>
          <TextField
            style={{ width: "100%" }}
            label="Confirm Password"
            type="password"
            margin="normal"
            value={blogerData.cpassword}
            onChange={(e) => handleChange(e, "cpassword")}
            variant="outlined"
            error={fieldErrors.cpassword.error}
          />
          <br />
          {fieldErrors.cpassword.error && (
            <Typography variant="body2" color="error">
              {fieldErrors.cpassword.errorMessage}
            </Typography>
          )}
        </div>
      </div>
      <div className="trm-cnd">
        <input
          type="checkbox"
          id="termsAndConditions"
          onChange={(e) =>
            setFieldErrors((prevFieldErrors) => ({
              ...prevFieldErrors,
              checkbx: {
                ...prevFieldErrors.checkbx,
                error: !e.target.checked,
              },
            }))
          }
        />
        <label htmlFor="termsAndConditions">Terms and Conditions</label>
        {fieldErrors.checkbx.error && (
          <span className="error-message">
            {fieldErrors.checkbx.errorMessage}
          </span>
        )}
      </div>
      <div className="signupbtn">
        <Button variant="contained" onClick={handleNextStep} color="primary">
          Next
        </Button>
      </div>
    </div>
  );
}
