import React from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Slider from "../components/Slider";
import Social from "../components/Social";
import Accounts from "../components/Accounts";
import Blog from "../components/Blog";
import Footer from "../components/Footer";
import Offers from "../components/Offers";
import Service from "../components/Service";
import "../index.css";

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <Slider />
      <Social />
      <Accounts />
      <Service />
      <Offers />
      <Blog />
      <Footer />
    </>
  );
};

export default Home;
