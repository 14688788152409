import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home"; // Corrected import statement
import Register from './pages/Register';
import Usersignup from './pages/Usersignup';                                               
import Vendorsignup from './pages/Vendorsignup'; 
import Affiliatesignup from './pages/Affiliatesignup'; 
import Advertisersignup from './pages/Advertisersignup'; 
import Instructorsignup from './pages/Instructorsignup'; 
import Blogersignup from './pages/Blogersignup'; 
import Login from './pages/Login'; 
import Information from './pages/Information'; 
import StepContext from './StepContext';
import StepContext2 from './StepContext2';
import StepContext3 from './StepContext3';
import StepContext4 from './StepContext4';
import StepContext5 from './StepContext5';
import StepContext6 from './StepContext6';
import Userstepform from './pages/Userstepform';
import Forgoten from './pages/Forgoten';
import Newpassword from './pages/Newpassword';
import Newforgotemail from './pages/Newforgotemail';
import Forgotemail from './pages/Forgotemail';
import NewEmail from './pages/NewEmail';
import Profile from './pages/profile.js';
import Userprofile from './pages/Useredit-profile';
function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/Register" element={<Register />} />
                <Route exact path="/Usersignup" element={<Usersignup />} />
                <Route exact path="/Userstepform" element={<StepContext><Userstepform /></StepContext>} />
                <Route exact path="/Vendorsignup" element={<StepContext2><Vendorsignup /></StepContext2>} />
                <Route exact path="/Affiliatesignup" element={<StepContext3><Affiliatesignup /></StepContext3>} />
                <Route exact path="/Advertisersignup" element={<StepContext4><Advertisersignup /></StepContext4>} />
                <Route exact path="/Instructorsignup" element={<StepContext5><Instructorsignup /></StepContext5>} />
                <Route exact path="/Blogersignup" element={<StepContext6><Blogersignup /></StepContext6>} />
                <Route exact path="/Login" element={<Login />} />
                <Route exact path="/Information" element={<Information />} />
                <Route exact path="/Forgoten" element={<Forgoten />} />
                <Route exact path="/Newpassword" element={<Newpassword />} />
                <Route exact path="/Newforgotemail" element={<Newforgotemail />} />
                <Route exact path="/Forgotemail" element={<Forgotemail />} />
                <Route exact path="/NewEmail" element={<NewEmail />} />
                <Route exact path="/Profile" element={<Profile />} />
                <Route exact path="/Userprofile" element={<Userprofile />} />
                
            </Routes>
        </Router>
    );
}

export default App;
