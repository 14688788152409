import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import sclm from "../images/sclm.png";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

const ChangeEmail = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(""); // State to store the new email
    const [error, setError] = useState(""); // State to store error messages
    const [token, setToken] = useState(""); // State to store the token

    useEffect(() => {
        // Extract token from URL
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token');
        setToken(tokenParam);
    }, []);

    const backgroundStyle = {
        backgroundImage: `url(${sclm})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 'auto', 
        padding: '70px 0 60px 0',
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value); // Update the email state
    };

    const handleSubmit = () => {
        // Reset error message
        setError("");

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError("Invalid email address");
            return;
        }

        // Send Axios request to backend with email and token
        axios.post("http://localhost:9000/NewEmail", { email, token })
            .then(data => {
                if (data.status === 200) {
                    toast.success(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setTimeout(() => {
                        navigate("/login");
                    }, 5000);
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        className: "toast-error",
                    });
                } else if (err.response && err.response.status === 500) {
                    toast.error(err.response.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        className: "toast-error",
                    });
                }
            });
    };

    return (
        <div>
            <ToastContainer />
            <div className="pass-fr" style={backgroundStyle}>
                <div className="container">
                    <div className="form-user" id="mx-wdth-lgn">
                        <h3>Change Email</h3>
                        <TextField
                            style={{ width: "100%" }}
                            label="New Email"
                            type="email"
                            margin="normal"
                            variant="outlined"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div className="sndbtn">
                            <button type="button" onClick={handleSubmit}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    );
};

export default ChangeEmail;
