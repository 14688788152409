import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Signupp from '../components/Signupp.js'

import "../index.css";

const Register = () => {
return(
    <div>
    <Header />
    <Signupp />
    <Footer />
    </div>
)

}


export default Register;