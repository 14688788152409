import React from 'react';
import '../css/accounts.css'
import Grid from '@mui/material/Grid';
import user from '../images/user.jpg';
import vend from '../images/vend.jpg';
import aff from '../images/aff.jpg';
import ins from '../images/ins.jpg';
import adv from '../images/adv.jpg';
import blg from '../images/blg.jpg';


const Accounts = () =>{

    return(
        <div className="">
            <div className="container">
                <div className="social-media">
                    <h2>Multiple Unique Accounts Creation</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>USER ACCOUNTS</h3>
                                    <p>As User</p>
                                    <button>VIEW MORE</button>
                                </div>    
                            </div>    
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img src={user} alt="user" />
                        </Grid>
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <img className="mbl-spce" src={vend} alt="vend" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Vendor ACCOUNTS</h3>
                                    <p>As Vendor</p>
                                    <button>VIEW MORE</button>
                                </div>    
                            </div>    
                        </Grid>      
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>AFFILIATE ACCOUNTS</h3>
                                    <p>AS Affiliate</p>
                                    <button>VIEW MORE</button>
                                </div>    
                            </div>    
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img src={aff} alt="aff" />
                        </Grid>
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <img className="mbl-spce" src={ins} alt="ins" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>INSTRUCTOR ACCOUNTS</h3>
                                    <p>AS Instructor</p>
                                    <button>VIEW MORE</button>
                                </div>    
                            </div>    
                        </Grid>      
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>ADVERTISER ACCOUNTS</h3>
                                    <p>AS Advertiser</p>
                                    <button>VIEW MORE</button>
                                </div>    
                            </div>    
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img src={adv} alt="adv" />
                        </Grid>
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <img className="mbl-spce" src={blg} alt="blg" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>BLOGGER ACCOUNTS</h3>
                                    <p>AS Blogger</p>
                                    <button>VIEW MORE</button>
                                </div>    
                            </div>    
                        </Grid>      
                    </Grid>	
                 
                    
                </div>    
            </div>
        </div>  
    );

}

export default Accounts;