import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import sclm from "../images/sclm.png";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
const Changepassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [token, setToken] = useState(""); // State to store the token

    useEffect(() => {
        // Extract token from URL
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token');
        setToken(tokenParam);
    }, []);

    const backgroundStyle = {
        backgroundImage: `url(${sclm})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 'auto', 
        padding: '70px 0 60px 0',
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value); 
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value); 

        // Check if confirm password matches new password
        if (value !== password) {
            setError("Passwords do not match");
        } else {
            setError("");
        }
    };

    const handleSubmit = () => {
        // Reset error message
        setError("");

        // Check if passwords meet criteria
        if (password.length < 8) {
            setError("Password must be at least 8 characters long");
            return;
        }

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        // Passwords are valid, proceed with saving
        // Send Axios request to backend with token
        axios.post("http://localhost:9000/change-pass", { password, token })
            .then(data => {
                if (data.status === 200) {
                    toast.success(data.data.message, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    });
                    setTimeout(()=>{
                        navigate("/login");
          
                      },5000)
                  }
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.error, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      className: "toast-error",
                    });
                  }else if (err.response && err.response.status === 500) {
                    toast.error(err.response.data.error, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      className: "toast-error",
                    });
                  }
            });
    };

    return (
        
        <div>
            <ToastContainer />
            <div className="pass-fr" style={backgroundStyle}>
                <div className="container">
                    <div className="form-user" id="mx-wdth-lgn">
                        <h3>Change Password</h3>
                        <TextField
                            style={{ width: "100%" }}
                            label="New Password"
                            type="password"
                            margin="normal"
                            variant="outlined"
                            value={password} 
                            onChange={handlePasswordChange} 
                        />
                        <TextField
                            style={{ width: "100%" }}
                            label="Confirm Password"
                            type="password"
                            margin="normal"
                            variant="outlined"
                            value={confirmPassword} 
                            onChange={handleConfirmPasswordChange} 
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div className="sndbtn">
                            <button type="button" onClick={handleSubmit}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    );
};

export default Changepassword;
