import React from 'react';
import '../css/accounts.css'
import Grid from '@mui/material/Grid';
import un from '../images/un.jpg';
import im from '../images/im.jpg';
import prs from '../images/prs.jpg';
import ecm from '../images/ecm.jpg';
import pur from '../images/pur.jpg';
import sell from '../images/sell.png';
import mony from '../images/mony.jpg';
import dir from '../images/dir.jpg';
import news from '../images/news.jpeg';
import scll from'../images/scll.png';
const Service = () =>{

    return(
        <div className="">
            <div className="container">
                <div className="social-media">
                    <h2>Multiple Web Features and Events For Usage</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Create Unique, Impressive, <br></br>Personal Profile Webpage</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                                </div>    
                            </div>    
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img src={prs} alt="prs" />
                        </Grid>
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <img className="mbl-spce" src={ecm} alt="ecm" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Ecommerce Tradings</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                                </div>    
                            </div>    
                        </Grid>      
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Purchase Products & Services with Confidences</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                                </div>    
                            </div>    
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img src={pur} alt="pur" />
                        </Grid>
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <img className="mbl-spce" src={sell} alt="sell" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Sell Products & Services with Confidences</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                                </div>    
                            </div>    
                        </Grid>      
                    </Grid>	

                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Earn Money Easily and Directly</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                                </div>    
                            </div>    
                        </Grid>
                    <Grid item xs={12} sm={6}>
                            <img src={mony} alt="mony" />
                        </Grid>
                        
                        
                    </Grid>	

                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                            <img className="mbl-spce" src={dir} alt="dir" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Direct Teaching</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                                </div>    
                            </div>    
                        </Grid>
                      
                    </Grid>	
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Direct and Constant Worldwide <br></br>News & Informations</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                                </div>    
                            </div>    
                        </Grid> 
                        <Grid item xs={12} sm={6}>
                            <img src={news} alt="news" />
                        </Grid>
                            
                    </Grid>	
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <img className="mbl-spce" src={scll} alt="scll" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="rkng-cnvrt">
                                <div className="">
                                    <h3>Connecting with people</h3>
                                    <p>more than just music</p>
                                    <button>VIEW MORE</button>
                                </div>    
                            </div>    
                        </Grid>      
                    </Grid>	
                    
                </div>    
            </div>
        </div>  
    );

}

export default Service;