import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import blog from "../images/blog.png";
import "../css/blog.css";

import blogg from "../images/blogg.png";
import bloggg from "../images/bloggg.png";
class Blog extends Component {
  render() {
    return (
      <div className="container">
        <div className="blog">
          <h3>Latest from The Blog</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
          <div className="prsnl-blog">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img src={blog} alt="blog" />
                <span>Personal Adviser</span>
                <h4>Computer and Using Some Color</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad
                  possimus magm maiores.Ipa Lorem ipsum dolor sit amet,
                  consectetur adiic Sunt sed ad posimus magnam maores.{" "}
                </p>
              </Grid>
              <Grid item xs={12} sm={4}>
                <img src={blogg} alt="blogg" />
                <span>Personal Adviser</span>
                <h4>Computer and Using Some Color</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad
                  possimus magm maiores.Ipa Lorem ipsum dolor sit amet,
                  consectetur adiic Sunt sed ad posimus magnam maores.{" "}
                </p>
              </Grid>
              <Grid item xs={12} sm={4}>
                <img src={bloggg} alt="bloggg" />
                <span>Personal Adviser</span>
                <h4>Computer and Using Some Color</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipielit. Sunt sed ad
                  possimus magm maiores.Ipa Lorem ipsum dolor sit amet,
                  consectetur adiic Sunt sed ad posimus magnam maores.{" "}
                </p>
              </Grid>
            </Grid>
          </div>
          <div className="sbcrb">
            <h5>Subscribe to Our Newsletter</h5>
            <input placeholder="Enter Keyword Here" type="text" />
            <div>
              <button>Search</button>
            </div>
      
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
