import React, { useState} from "react";
import Instructorsignup from "./pages/Instructorsignup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const MultiStepContext = React.createContext();

const StepContext = () => {
  const [currentStep, setStep] = useState(1);
  const [instructorData, setInstructorData] = useState({});
  



  


  // vendor data 

  async function instructorsubmitData() {
  
    
    

    try {
      await axios
        .post("http://localhost:9000/Instructorsignup", instructorData)
        .then((data) => {
          if (data.status === 200) {
            setInstructorData({});
            toast.success(data.data, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        })
        .catch((err) => {
          
            if (err.response && err.response.status === 500) {
              
              toast.error(err.response.data, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: "toast-error", // Custom CSS class for error messages
              });
            } else {
              toast.error("An error occurred. Please try again later.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: "toast-error", // Custom CSS class for error messages
              });
            }
          
          console.log(err);
        });
    } catch {}

    setStep(1);
  }

  return (
    <div>
      <ToastContainer />
      <MultiStepContext.Provider
        value={{
          currentStep,
          setStep,
          instructorData,
          setInstructorData,
          instructorsubmitData
        }}
      >
        
        <Instructorsignup />
      </MultiStepContext.Provider>
    </div>
  );
};

export default StepContext;
