import * as React from "react";
import PropTypes from "prop-types";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import { styled } from "@mui/system";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";

export default function Product() {
  return (
    <Select placeholder="All Catergories">
      {products.map((product) => (
        <Option key={product.code} value={product.code} label={product.label}>
          {product.label}
        </Option>
      ))}
    </Select>
  );
}

const Select = React.forwardRef(function CustomSelect(props, ref) {
  const slots = {
    root: Button,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  };

  return <BaseSelect {...props} ref={ref} slots={slots} />;
});

Select.propTypes = {
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popup: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Button = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const StyledButton = styled("button", { shouldForwardProp: () => true })(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 17px!important;
  box-sizing: border-box;
  min-width: 138px;
  padding: 0px 12px;
  border-radius: 6px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  position: relative;
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 180px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  max-height: 250px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  `
);

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  & img {
    margin-right: 10px;
  }
  `
);

const Popup = styled("div")`
  z-index: 1;
`;

const products = [
  { code: "P001", label: "Antiques" },
  { code: "P002", label: "Art" },
  { code: "P003", label: "Baby" },
  { code: "P004", label: "Books & Magazines" },
  { code: "P005", label: "Business & Industrial" },
  { code: "P006", label: "Cameras & Photo" },
  { code: "P007", label: "Cell Phones & Accessories" },
  { code: "P008", label: "Clothing, Shoes & Accessories" },
  { code: "P009", label: "Coins & Paper Money" },
  { code: "P010", label: "Collectibles" },
  { code: "P011", label: "Computers/Tablets & Networking" },
  { code: "P012", label: "Consumer Electronics" },
  { code: "P013", label: "Crafts" },
  { code: "P014", label: "Dolls & Bears" },
  { code: "P015", label: "Movies & TV" },
  { code: "P016", label: "Entertainment Memorabilia" },
  { code: "P017", label: "Gift Cards & Coupons" },
  { code: "P018", label: "Health & Beauty" },
  { code: "P019", label: "Home & Garden" },
  { code: "P020", label: "Jewelry & Watches" },
  { code: "P021", label: "Music" },
  { code: "P022", label: "Musical Instruments & Gear" },
  { code: "P023", label: "Pet Supplies" },
  { code: "P024", label: "Pottery & Glass" },
  { code: "P025", label: "Real Estate" },
  { code: "P026", label: "Specialty Services" },
  { code: "P027", label: "Sporting Goods" },
  { code: "P028", label: "Sports Mem, Cards & Fan Shop" },
  { code: "P029", label: "Stamps" },
  { code: "P030", label: "Tickets & Experiences" },
  { code: "P031", label: "Toys & Hobbies" },
  { code: "P032", label: "Travel" },
  { code: "P033", label: "Video Games & Consoles" },
  { code: "P034", label: "Arts & Crafts" },
  { code: "P035", label: "Automotive" },
  { code: "P036", label: "Beauty & Personal Care" },
  { code: "P037", label: "Boys' Fashion" },
  { code: "P038", label: "Computers" },
  { code: "P039", label: "Deals" },
  { code: "P040", label: "Digital Music" },
  { code: "P041", label: "Electronics" },
  { code: "P042", label: "Girls' Fashion" },
  { code: "P043", label: "Health & Household" },
  { code: "P044", label: "Home & Kitchen" },
  { code: "P045", label: "Industrial & Scientific" },
  { code: "P046", label: "Kindle Store" },
  { code: "P047", label: "Luggage" },
  { code: "P048", label: "Men's Fashion" },
  { code: "P049", label: "Music, CDs & Vinyl" },
  { code: "P050", label: "Prime Video" },
  { code: "P051", label: "Software" },
  { code: "P052", label: "Sports & Outdoors" },
  { code: "P053", label: "Tools & Home Improvement" },
  { code: "P054", label: "Toys & Games" },
  { code: "P055", label: "Women's Fashion" },
  { code: "P056", label: "Boats & Jet Skis" },
  { code: "P057", label: "Cars & Vehicles" },
  { code: "P058", label: "Clothing & Jewellery" },
  { code: "P059", label: "Community" },
  { code: "P060", label: "Electronics & Computer" },
  { code: "P061", label: "Jobs" },
  { code: "P062", label: "Miscellaneous Goods" },
  { code: "P063", label: "Pets" },
  { code: "P064", label: "Services For Hire" },
  { code: "P065", label: "Sport & Fitness" }
];
