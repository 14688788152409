import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Container, List, ListItem, ListItemIcon } from "@mui/material";
import logo2 from '../images/logo2.png';
import Products from './Products';
import '../css/footer.css';

class Footer extends Component {
  render() {
    return (
      <div>
         <div className='container-fluid socialicn'>
            <div className="container socialsite">
                <List id="social-icons" style={{ display: "flex", }}>
                  <ListItem className='scl-medi'>
                    <ListItemIcon>
                      <FacebookOutlinedIcon className="smicons" />
                    </ListItemIcon>
                  </ListItem>
                  <ListItem className='scl-medi'>
                    <ListItemIcon>
                      <TwitterIcon className="smicons" />
                    </ListItemIcon>
                  </ListItem>
                  <ListItem className='scl-medi'>
                    <ListItemIcon>
                      <LinkedInIcon className="smicons" />
                    </ListItemIcon>
                  </ListItem>
                  <ListItem className='scl-medi'>
                    <ListItemIcon>
                      <YouTubeIcon className="smicons" />
                    </ListItemIcon>
                  </ListItem>
                </List>

                <div className='social-lnks'>
                  <li><a href="#">Advertise With Us</a></li>
                  <li><a href="#">Contact Us</a></li>
                  <li><a href="#">About Us</a></li>
                  <li><a href="#">Legal</a></li>
                  <li><a href="#">Report Bugs</a></li>
                  <li><a href="#">Site Map</a></li>
                  <li><a href="#">FAQ</a></li>
                 </div> 
                  

              </div>
            </div>
        <footer>
           

          <div className="container">
            <div className="footer">
             
              
              {/* <Grid container spacing={2}> */}
                {/* <Grid item xs={12} sm={4}>
                  <img src={logo} alt="logo" />

                  {/* <Link to="/"><p className='lorem'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p> </Link> */}
                {/* </Grid>  */}

                {/* <Grid item xs={12} sm={4}>
                  {/* <h3>Quick Links</h3> */}
                  {/* <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="#">Company</Link></li>
                    <li><Link to="#">Report Bug</Link></li>
                    <li><Link to="#">Careers</Link></li>
                    <li><Link to="#">FAQ</Link></li>
                    <li><Link to="#">Contact us</Link></li>
                    <li><Link to="#">Privacy policy</Link></li>
                    <li><Link to="#">Terms & conditions</Link></li>
                    <li><Link to="#">Sitemap</Link></li>
                    <li><Link to="#">Advertise With Us</Link></li>
                  </ul>
                </Grid> */} 

<div className="Unique_Services">
        <Container className='respon-padd'>
          <div className="unique">
            <Link to="/" className="lnks">
              <h2 id="heade">Unique Services</h2>
            </Link>
            {/* <Country /> */}
           
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} className='usrul'>
              <List id="proflinked">
                <ListItem>
                  <a href="#">User</a>
                </ListItem>
                <ListItem>
                  <a href="#">Vendor</a>
                </ListItem>

                <ListItem>
                  <a href="#">Affiliate</a>
                </ListItem>

                <ListItem>
                  <a href="#"> Instructor</a>
                </ListItem>
                <ListItem>
                  <a href="#">Blogger</a>
                </ListItem>
                <ListItem>
                  <a href="#"> Advertiser</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="product_Services">
        <Container className='respon-padd'>
          <div className="unique">
            <Link to="/" className="lnks">
              <h2 id="heade">Products Services</h2>
            </Link>
            {/* <Country /> */}
            <Products />
          </div>

         
        </Container>
      </div>
      {/* <div className="tradesmenServices">
        <Container>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="trds">Others Services</h2>
            </Link>
          
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Tradesmen</a>
                </ListItem>
                <ListItem>
                  <a href="#">Events</a>
                </ListItem>

                <ListItem>
                  <a href="#">Holiday</a>
                </ListItem>
                
                
              </List>
            </Grid>
            <Grid item xs={3}>
              <List id="proflinks1">
                <ListItem>
                  <a href="#">Professional </a>
                </ListItem>
                <ListItem>
                  <a href="#">Advertising</a>
                </ListItem>
                <ListItem>
                  <a href="#">Special Offers</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3}>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Real Estate </a>
                </ListItem>

                <ListItem>
                  <a href="#">Accommodation </a>
                </ListItem>
               
              </List>
            </Grid>

            <Grid item xs={3}>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Social</a>
                </ListItem>

                <ListItem>
                  <a href="#">Ecommerce</a>
                </ListItem>
               
              </List>
            </Grid>
          </Grid>
        </Container>
      </div> */}
   {/* <div className="Specific_Services">
        <Container>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="spcserv">Specific Services</h2>
            </Link>
           
           
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Customer</a>
                </ListItem>
                <ListItem>
                  <a href="#">Buyer</a>
                </ListItem>

                <ListItem>
                  <a href="#">Seller</a>
                </ListItem>

                <ListItem>
                  <a href="#">Buyer's Agent</a>
                </ListItem>
                <ListItem>
                  <a href="#">Seller's Agent</a>
                </ListItem>
                <ListItem>
                  <a href="#">Real Estate company</a>
                </ListItem>
                
              </List>
            </Grid>
          </Grid>
        </Container>
      </div> */}

      <div className="tradesmenServices">
        <Container className='respon-padd'>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="trds">Tradesmen Services</h2>
            </Link>
          
          </div>
          <Grid container spacing={2} className='tdsname'>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Builder</a>
                </ListItem>
                <ListItem>
                  <a href="#">BrickLayer</a>
                </ListItem>

                <ListItem>
                  <a href="#">Electricians</a>
                </ListItem>
               
              </List>
            </Grid>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks1">
                <ListItem>
                  <a href="#">Plumbers</a>
                </ListItem>
                <ListItem>
                  <a href="#">Stonemason</a>
                </ListItem>
                <ListItem>
                  <a href="#">Carpenter</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Painter</a>
                </ListItem>

                <ListItem>
                  <a href="#">Welder</a>
                </ListItem>
                <ListItem>
                  <a href="#">Tiler</a>
                </ListItem>
                <ListItem>
                  <a href="#">Landscaper</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Plasterer</a>
                </ListItem>

                <ListItem>
                  <a href="#">Glazier</a>
                </ListItem>
                <ListItem>
                  <a href="#">Handyman</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="professional">
        <Container className='respon-padd'>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="prps">Professional Services</h2>
            </Link>
            {/* <Country /> */}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Financial Institution</a>
                </ListItem>
                <ListItem>
                  <a href="#">Financial Advisor</a>
                </ListItem>

                <ListItem>
                  <a href="#">Mortgage Broker</a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks1">
                <ListItem>
                  <a href="#">Lawyer</a>
                </ListItem>
                <ListItem>
                  <a href="#">Accountant</a>
                </ListItem>
                <ListItem>
                  <a href="#">Book Keeper</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Building Engineer</a>
                </ListItem>

                <ListItem>
                  <a href="#">Building Designer</a>
                </ListItem>
                <ListItem>
                  <a href="#">Architects</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Pest Control Inspector</a>
                </ListItem>

                <ListItem>
                  <a href="#">Soil Technician</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>

{/* real-estate service */}
<div className="Unique_Services">
        <Container className='respon-padd'>
          <div className="unique">
            <Link to="/" className="lnks">
              <h2 id="heade">Real Estate Services  </h2>
            </Link>
            {/* <Country /> */}
           
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} className='usrul'>
              <List id="proflinked">
                <ListItem>
                  <a href="#">Buyer</a>
                </ListItem>
                <ListItem>
                  <a href="#">Seller</a>
                </ListItem>

                <ListItem>
                  <a href="#">Buyer's Agent</a>
                </ListItem>

                <ListItem>
                  <a href="#"> Seller's Agent</a>
                </ListItem>
                <ListItem>
                  <a href="#">Real Estate company</a>
                </ListItem>
               
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>


{/* social service */}
<div className="Unique_Services">
        <Container className='respon-padd'>
          <div className="unique">
            <Link to="/" className="lnks">
              <h2 id="heade">Social Services  </h2>
            </Link>
            {/* <Country /> */}
           
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List id="proflinked">
                <ListItem>
                  <a href="#">Dating</a>
                </ListItem>
                <ListItem>
                  <a href="#">Peers to Peers </a>
                </ListItem>

                <ListItem>
                  <a href="#">Group Chats</a>
                </ListItem>

                <ListItem>
                  <a href="#">  Forum Discussions</a>
                </ListItem>
                <ListItem>
                  <a href="#">Jobs Seeking</a>
                </ListItem>
               
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>

{/* events service */}
<div className="tradesmenServices">
        <Container className='respon-padd'>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="trds">Events Services </h2>
            </Link>
   
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3} className='xyz tdsul'>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Sportings</a>
                </ListItem>
                <ListItem>
                  <a href="#">Schools Groups</a>
                </ListItem>

                <ListItem>
                  <a href="#">Financial Centres</a>
                </ListItem>
                
              </List>
            </Grid>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks1">
                <ListItem>
                  <a href="#">Social Gathering</a>
                </ListItem>
                <ListItem>
                  <a href="#">Business Groups</a>
                </ListItem>
                <ListItem>
                  <a href="#">Special Events</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Scenic Tours</a>
                </ListItem>

                <ListItem>
                  <a href="#">Work Groups</a>
                </ListItem>
               
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Holidays Tours</a>
                </ListItem>

                <ListItem>
                  <a href="#">Learning Centres  </a>
                </ListItem>
              
              </List>
            </Grid>
          </Grid>

        </Container>
      </div>
      
{/* Adertising Services  */}
<div className="Unique_Services">
        <Container className='respon-padd'>
          <div className="unique">
            <Link to="/" className="lnks">
              <h2 id="heade">Adertising Services   </h2>
            </Link>
            {/* <Country /> */}
        
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} className='advrs-lnk'>
              <List id="proflinked">
                <ListItem>
                  <a href="#"> Webpage Advertising</a>
                </ListItem>
                <ListItem>
                  <a href="#">Direct Advertising</a>
                </ListItem>

                <ListItem>
                  <a href="#"> Affiliate Advertising</a>
                </ListItem>

           
               
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>


{/* Accommodation Services  */}

<div className="professional">
        <Container className='respon-padd'>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="prps">Accommodation Services </h2>
            </Link>
            {/* <Country /> */}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks">
                <ListItem>
                  <a href="#">House Rentals</a>
                </ListItem>
                <ListItem>
                  <a href="#">  Granny Flat Rentals</a>
                </ListItem>

                <ListItem>
                  <a href="#">Hotels</a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks1">
                <ListItem>
                  <a href="#">  Unit Rentals</a>
                </ListItem>
                <ListItem>
                  <a href="#">Garage Rentals</a>
                </ListItem>
                <ListItem>
                  <a href="#">B&B</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#"> Granny Flat Rentals</a>
                </ListItem>

                <ListItem>
                  <a href="#"> Land Rentals</a>
                </ListItem>
               
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Hotels</a>
                </ListItem>

                <ListItem>
                  <a href="#">Motels</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>


      {/* Holiday Services  */}

<div className="professional">
        <Container className='respon-padd'>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="prps">Holiday Services  </h2>
            </Link>
            {/* <Country /> */}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Tour Packages</a>
                </ListItem>
                <ListItem>
                  <a href="#">Taxi</a>
                </ListItem>

                <ListItem>
                  <a href="#">Air Fares</a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={3} className='tdsul'>
              <List id="proflinks1">
                <ListItem>
                  <a href="#">Holiday Packages</a>
                </ListItem>
                <ListItem>
                  <a href="#">Car Rentals</a>
                </ListItem>
                <ListItem>
                  <a href="#">Trains</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#"> Backpacker Hostels</a>
                </ListItem>

                <ListItem>
                  <a href="#"> Duty Free Centres</a>
                </ListItem>
                <ListItem>
                  <a href="#"> Buses</a>
                </ListItem>
               
              </List>
            </Grid>

            <Grid item xs={3} className='tdsul resptd'>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Tour Guides</a>
                </ListItem>

                <ListItem>
                  <a href="#">Informations Centres</a>
                </ListItem>
                <ListItem>
                  <a href="#">Ferry Boats</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>
{/* Special Offers Services  */}
<div className="Unique_Services">
        <Container className='respon-padd'>
          <div className="unique">
            <Link to="/" className="lnks">
              <h2 id="heade">Special Offers Services  </h2>
            </Link>
            {/* <Country /> */}
        
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List id="proflinked">
                <ListItem>
                  <a href="#"> Discounts</a>
                </ListItem>
                <ListItem>
                  <a href="#"> Limited Offers</a>
                </ListItem>

                <ListItem>
                  <a href="#">Time Limit Offers</a>
                </ListItem>

                <ListItem>
                  <a href="#">Second hand Items</a>
                </ListItem>
                <ListItem>
                  <a href="#"> Refurbish Items</a>
                </ListItem>
               
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>

            </div>
            
          </div>
        </footer>
        <p className="copyright">Copyright © 2024</p>
      </div>
    );
  }
}

export default Footer;
