import React from "react";
import "../css/profile.css";
import Grid from '@mui/material/Grid';
import blg from '../images/blg.jpg';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import shadows from "@mui/material/styles/shadows";

function Profile(){

    return(
        <div>
           
                <div className="container">
                    <div className="usr-dhbrd">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div className="leftside">
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <img src={blg} alt="blg" />
                                        </Grid>   
                                        <Grid item xs={7}>
                                            <h3>rohit kumar</h3>
                                            <p>ID: User22</p>
                                        </Grid>   
                                    </Grid>   
                                    <ul>
                                        <li><i class="fa fa-home" aria-hidden="true"></i> Visit</li>
                                        <li>
                                        <Accordion
                                            sx={{ padding: 0, boxShadow: 'unset', border: 'unset' }}
                                        >
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            sx={{ padding: 0, boxShadow: 'unset', border: 'unset' }}
                                            >
                                            <i class="fa fa-home" aria-hidden="true"></i> Dashboard
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ul className="edt-prfl-pswrd">
                                                    <li>Edit Profile</li>
                                                    <li>Password</li>
                                                </ul>
                                            </AccordionDetails>
                                        </Accordion>
      
      
                                        </li>
                                        <li><i class="fa fa-cogs" aria-hidden="true"></i> General</li>
                                        <li><i class="fa fa-outdent" aria-hidden="true"></i>My Order</li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i> Review</li>
                                        <li><i class="fa fa-comments" aria-hidden="true"></i> Chat</li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className="two-bx">
                                            <h3>About</h3>
                                            <p>Joined 14 Feb, 2024</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="two-bx">
                                            <h3>Favourite Shops <a href="#">See More</a></h3>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <div className="two-bxy">
                                            <h3>Today Order</h3>
                                            <p>0</p>
                                            <i class="fa fa-repeat" aria-hidden="true"></i>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="two-bxu">
                                            <h3>Month Order</h3>
                                            <p>0</p>
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="two-bxi">
                                            <h3>Year Order</h3>
                                            <p>0</p>
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>           
        </div>
    )
}

export default Profile;