import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Edituserprofile from '../components/Useredit-profile'; 

function Userprofile() {
    return (
        <div>
            <Header />
            <Edituserprofile />
            <Footer />
        </div>
    );
}

export default Userprofile;
