import React, { useState, useEffect, useRef } from "react";
import "../css/currencydropdown.css";
import logo1 from "../images/us.png";
import logo2 from "../images/er.jpg";
import logo3 from "../images/jp.png";
import logo4 from "../images/brts.jpg";
import logo5 from "../images/aus.png";
import logo6 from "../images/cn.jpg";
import logo7 from "../images/sw.png";
import logo8 from "../images/ch.png";
import logo9 from "../images/nz.png";
import logo10 from "../images/sou.png";
import logo11 from "../images/arb.png";
import logo12 from "../images/az.jpg";
import logo13 from "../images/bgn.png";
import logo14 from "../images/bah.png";
import logo15 from "../images/bnd.jpg";
import logo16 from "../images/brl.png";
import logo17 from "../images/bot.png";
import logo18 from "../images/cz.png";
import logo19 from "../images/den.png";
import logo20 from "../images/egp.jpg";
import logo21 from "../images/fij.png";
import logo22 from "../images/hng.png";
import logo23 from "../images/hun.png";
import logo24 from "../images/indo.jpg";
import logo25 from "../images/is.png";
import logo26 from "../images/ind.png";
import logo27 from "../images/irr.jpg";
import logo28 from "../images/ken.png";
import logo29 from "../images/krw.png";
import logo30 from "../images/kuw.png";
import logo31 from "../images/sri.png";
import logo32 from "../images/mau.png";
import logo33 from "../images/mex.png";
import logo34 from "../images/myr.jpg";
import logo35 from "../images/nor.png";
import logo36 from "../images/om.png";
import logo37 from "../images/phi.jpg";
import logo38 from "../images/pl.png";
import logo39 from "../images/qat.jpg";
import logo40 from "../images/rom.jpg";
import logo41 from "../images/rub.png";
import logo42 from "../images/sau.png";
import logo43 from "../images/swe.png";
import logo44 from "../images/sing.png";
import logo45 from "../images/tha.jpg";
import logo46 from "../images/tur.png";
import logo47 from "../images/tri.png";
import logo48 from "../images/twd.png";
import logo49 from "../images/uga.jpg";
import logo50 from "../images/vnd.jpg";
import logo51 from "../images/eas.png";

const CurrencyDropdown = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const filterRequestRef = useRef(null);
  const selectedItemRef = useRef(null);

  // Event listener to hide dropdown when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterRequestRef.current &&
        !filterRequestRef.current.contains(event.target) &&
        selectedItemRef.current &&
        !selectedItemRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    }
    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Function to handle selecting a currency
  function handleCurrencySelect(currencyCode, currencyName, imageUrl) {
    selectedItemRef.current.textContent = `${currencyCode} - ${currencyName}`;
    const img = document.createElement("img");
    img.src = imageUrl;
    selectedItemRef.current.insertBefore(
      img,
      selectedItemRef.current.firstChild
    );
    setDropdownVisible(false);
  }

  return (
    <div className="currency-dropdown">
      <span
        ref={selectedItemRef}
        id="selected-item"
        className="selected-item"
        onClick={() => setDropdownVisible(!isDropdownVisible)}
      >
        Currencies
      </span>

      <ul
        ref={filterRequestRef}
        id="filterrequest"
        className="filterrequest"
        style={{ display: isDropdownVisible ? "block" : "none" }}
      >
        <li
          onClick={() =>
            handleCurrencySelect("USD", "United States Dollar", logo1)
          }
        >
          <img src={logo1} />
          USD - United States Dollar
        </li>

        <li
          onClick={() =>
            handleCurrencySelect("EUR", "European Union Euro", logo2)
          }
        >
          <img src={logo2} />
          EUR - European Union Euro
        </li>

        <li onClick={() => handleCurrencySelect("JPY", "Japanese Yen", logo3)}>
          <img src={logo3} />
          JPY - Japanese Yen
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("GBP", "British Pound Sterling", logo4)
          }
        >
          <img src={logo4} />
          GBP - British Pound Sterling
        </li>
        <li
          onClick={() => handleCurrencySelect("AUD", "Australia Dollar", logo5)}
        >
          <img src={logo5} />
          AUD - Australia Dollar
        </li>
        <li
          onClick={() => handleCurrencySelect("CAD", "Canadian Dollar", logo6)}
        >
          <img src={logo6} />
          CAD - Canadian Dollar
        </li>

        <li onClick={() => handleCurrencySelect("CHF", "Swiss Franc", logo7)}>
          <img src={logo7} />
          CHF - Swiss Franc
        </li>
        <li onClick={() => handleCurrencySelect("CNH", "Chinese Yuan", logo8)}>
          <img src={logo8} />
          CNH - Chinese Yuan
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("NZD", "New Zealand Dollar", logo9)
          }
        >
          <img src={logo9} />
          NZD - New Zealand Dollar
        </li>

        <li
          onClick={() =>
            handleCurrencySelect("ZAR", "South African Rand", logo10)
          }
        >
          <img src={logo10} />
          ZAR - South African Rand
        </li>
        <li onClick={() => handleCurrencySelect("AED", "UAE Dirham", logo11)}>
          <img src={logo11} />
          AED - UAE Dirham
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("AZN", "Azerbaijani new Manat", logo12)
          }
        >
          <img src={logo12} />
          AZN - Azerbaijani new Manat
        </li>
        <li
          onClick={() => handleCurrencySelect("BGN", "Bulgarian Lev", logo13)}
        >
          <img src={logo13} />
          BGN - Bulgarian Lev
        </li>
        <li
          onClick={() => handleCurrencySelect("BHD", "Bahrain Dinar", logo14)}
        >
          <img src={logo14} />
          BHD - Bahrain Dinar
        </li>

        <li
          onClick={() => handleCurrencySelect("BND", "Brunei Dollar", logo15)}
        >
          <img src={logo15} />
          BND - Brunei Dollar
        </li>
        <li
          onClick={() => handleCurrencySelect("BRL", "Brazilian Real", logo16)}
        >
          <img src={logo16} />
          BRL - Brazilian Real
        </li>
        <li
          onClick={() => handleCurrencySelect("BWP", "Botswana Pula", logo17)}
        >
          <img src={logo17} />
          BWP - Botswana Pula
        </li>
        <li onClick={() => handleCurrencySelect("CZK", "Czech Koruna", logo18)}>
          <img src={logo18} />
          CZK - Czech Koruna
        </li>
        <li onClick={() => handleCurrencySelect("DKK", "Danish Krone", logo19)}>
          <img src={logo19} />
          DKK - Danish Krone
        </li>

        <li
          onClick={() => handleCurrencySelect("EGP", "Egyptian Pound", logo20)}
        >
          <img src={logo20} />
          EGP - Egyptian Pound
        </li>
        <li onClick={() => handleCurrencySelect("FJD", "Fiji Dollar", logo21)}>
          <img src={logo21} />
          FJD - Fiji Dollar
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("HKD", "Hong Kong Dollar", logo22)
          }
        >
          <img src={logo22} />
          HKD - Hong Kong Dollar
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("HUF", "Hungarian Forint", logo23)
          }
        >
          <img src={logo23} />
          HUF - Hungarian Forint
        </li>

        <li
          onClick={() =>
            handleCurrencySelect("IDR", "Indonesian Rupiah", logo24)
          }
        >
          <img src={logo24} />
          IDR - Indonesian Rupiah
        </li>
        <li
          onClick={() => handleCurrencySelect("ILS", "Israeli Shekel", logo25)}
        >
          <img src={logo25} />
          ILS - Israeli Shekel
        </li>
        <li onClick={() => handleCurrencySelect("INR", "Indian Rupee", logo26)}>
          <img src={logo26} />
          INR - Indian Rupee
        </li>
        <li onClick={() => handleCurrencySelect("IRR", "Iran Rial", logo27)}>
          <img src={logo27} />
          IRR - Iran Rial
        </li>

        <li
          onClick={() => handleCurrencySelect("KES", "Kenyan Shilling", logo28)}
        >
          <img src={logo28} />
          KES - Kenyan Shilling
        </li>
        <li onClick={() => handleCurrencySelect("KRW", "South Korea", logo29)}>
          <img src={logo29} />
          KRW - South Korea
        </li>
        <li
          onClick={() => handleCurrencySelect("KWD", "Kuwaiti Dinar", logo30)}
        >
          <img src={logo30} />
          KWD - Kuwaiti Dinar
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("LKR", "Sri Lankan Rupee", logo31)
          }
        >
          <img src={logo31} />
          LKR - Sri Lankan Rupee
        </li>
        <li
          onClick={() => handleCurrencySelect("MUR", "Mauritius Rupee", logo32)}
        >
          <img src={logo32} />
          MUR - Mauritius Rupee
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("MXN", "Mexican New Peso", logo33)
          }
        >
          <img src={logo33} />
          MXN - Mexican New Peso
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("MYR", "Malaysia Ringgit", logo34)
          }
        >
          <img src={logo34} />
          MYR - Malaysia Ringgit
        </li>
        <li
          onClick={() => handleCurrencySelect("NOK", "Norwegian Krone", logo35)}
        >
          <img src={logo35} />
          NOK - Norwegian Krone
        </li>
        <li onClick={() => handleCurrencySelect("OMR", "Omaini Rial", logo36)}>
          <img src={logo36} />
          OMR - Omaini Rial
        </li>
        <li
          onClick={() => handleCurrencySelect("PHP", "Phillipine Peso", logo37)}
        >
          <img src={logo37} />
          PHP - Phillipine Peso
        </li>
        <li onClick={() => handleCurrencySelect("PLN", "Polish Zloty", logo38)}>
          <img src={logo38} />
          PLN - Polish Zloty
        </li>
        <li onClick={() => handleCurrencySelect("QAR", "Qatari Rial", logo39)}>
          <img src={logo39} />
          QAR - Qatari Rial
        </li>
        <li onClick={() => handleCurrencySelect("RON", "Romanian Leu", logo40)}>
          <img src={logo40} />
          RON - Romanian Leu
        </li>
        <li onClick={() => handleCurrencySelect("RUB", "Russia Ruble", logo41)}>
          <img src={logo41} />
          RUB - Russia Ruble
        </li>
        <li onClick={() => handleCurrencySelect("SAR", "Saudi Riyal", logo42)}>
          <img src={logo42} />
          SAR - Saudi Riyal
        </li>
        <li
          onClick={() => handleCurrencySelect("SEK", "Swedish Krone", logo43)}
        >
          <img src={logo43} />
          SEK - Swedish Krone
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("SGD", "Singapore Dollar", logo44)
          }
        >
          <img src={logo44} />
          SGD - Singapore Dollar
        </li>
        <li
          onClick={() => handleCurrencySelect("THB", "Thailand Baht", logo45)}
        >
          <img src={logo45} />
          THB - Thailand Baht
        </li>
        <li onClick={() => handleCurrencySelect("TRY", "Turkish Lira", logo46)}>
          <img src={logo46} />
          TRY - Turkish Lira
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("TTD", "Trinidad &amp; Tobago Dollar", logo47)
          }
        >
          <img src={logo47} />
          TTD - Trinidad &amp; Tobago Dollar
        </li>
        <li
          onClick={() => handleCurrencySelect("TWD", "Taiwan Dollar", logo48)}
        >
          <img src={logo48} />
          TWD - Taiwan Dollar
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("UGX", "Ugandan Shilling", logo49)
          }
        >
          <img src={logo49} />
          UGX - Ugandan Shilling
        </li>
        <li
          onClick={() => handleCurrencySelect("VND", "Vietnamese Dong", logo50)}
        >
          <img src={logo50} />
          VND - Vietnamese Dong
        </li>
        <li
          onClick={() =>
            handleCurrencySelect("XCD", "East Caribbean Dollar", logo51)
          }
        >
          <img src={logo51} />
          XCD - East Caribbean Dollar
        </li>
      </ul>
    </div>
  );
};

export default CurrencyDropdown;
