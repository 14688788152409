import {React,useState,useEffect} from "react";

import axios from "axios";
import "../css/signupform.css";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import sclm from "../images/sclm.png";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';




const Usersignupform = () => {

    const steps = [
        {
          label: 'Select campaign settings',
          description: <TextField 
          
          />
        },
        {
          label: 'Create an ad group',
          description: <TextField 
          />
        },
        {
          label: 'Create an ad',
          description: <TextField />
        },
      ];
      

    const [open, setOpen] = useState(false);

    // const handleClickOpen = () => {
      
    // };
  
    const handleClose = () => {
      setOpen(false);
    };
  const backgroundStyle = {
    backgroundImage: `url(${sclm})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "650px",
    padding: "70px 0",
  };


  const [formData, setFormData] = useState({
    firstname: {
      value: "",
      error: false,
      errorMessage: "First Name is required",
    },
    lastname: {
      value: "",
      error: false,
      errorMessage: "Last Name is required",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "Email is required",
    },
    phone: {
      value: "",
      error: false,
      errorMessage: "Phone Number is required",
    },
    address: {
      value: "",
      error: false,
      errorMessage: "Address is required",
    },
    password: {
      value: "",
      error: false,
      errorMessage: "You must enter a password",
    },
    cpassword: {
      value: "",
      error: false,
      errorMessage: "Passwords do not match",
    },
  });

  const handleSubmit = async(e) => {
    e.preventDefault();
    
    // Form validation
    let newFormValues = { ...formData };
    let formIsValid = true;

    // Check if any field is empty
    for (const field in newFormValues) {
      if (newFormValues[field].value.trim() === "") {
        newFormValues[field].error = true;
        formIsValid = false;
      }
    }

    // Check if passwords match
    if (newFormValues.password.value !== newFormValues.cpassword.value) {
      newFormValues.password.error = true;
      newFormValues.cpassword.error = true;
      formIsValid = false;
    }

    // If form is not valid, update state and return
    if (!formIsValid) {
      setFormData(newFormValues);
      return;
    }

    // Prepare data for submission
    const { firstname, lastname, email, phone, address, password } = newFormValues;
    const finalObj = {
      firstname: firstname.value,
      lastname: lastname.value,
      email: email.value,
      phone: phone.value,
      address: address.value,
      password: password.value,
    };

    // Submit data
    try {
        
         await axios.post("http://localhost:9000/signup", finalObj)
         .then((data)=>{
            
            if (data.status === 200) {
                alert(data);
                  toast.success("Signup successful! Your account has been created.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
               
                setOpen(true);
                }
         }).catch((err)=>{
            console.log(err);
         })
         
          // Corrected URL
        // 
    
          // Optionally, navigate to the sign-in page
          // setTimeout(() => {
          //   navigate("/signin");
          // }, 5000);
        }
       catch (error) {
        console.log(error);
        // Handle errors if any
      }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
        error: false,
      },
    });
  };

  useEffect(() => {
    // Email validation
    if (formData.email.value.trim() === "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: {
          ...prevFormData.email,
          error: true,
        },
      }));
    } else if (!/\S+@\S+\.\S+/.test(formData.email.value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: {
          ...prevFormData.email,
          error: true,
          errorMessage: "Invalid email address",
        },
      }));
    }
  }, [formData.email.value]);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className="container">
      <div className="form-user">
        <h3>User signup</h3>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div className="flx-input">
            <input
              type="text"
              className={`form-control ${formData.firstname.error ? "error" : ""}`}
              placeholder="Name*"
              value={formData.firstname.value}
              onChange={handleInputChange}
              name="firstname"
            />
            {formData.firstname.error && <span className="error-message">{formData.firstname.errorMessage}</span>}
            <input
              type="text"
              className={`form-control ${formData.lastname.error ? "error" : ""}`}
              placeholder="Last Name *"
              value={formData.lastname.value}
              onChange={handleInputChange}
              name="lastname"
            />
            {formData.lastname.error && <span className="error-message">{formData.lastname.errorMessage}</span>}
          </div>
          <div className="flx-input">
            <input
              type="text"
              className={`form-control ${formData.email.error ? "error" : ""}`}
              placeholder="Email Address*"
              value={formData.email.value}
              onChange={handleInputChange}
              name="email"
            />
            {formData.email.error && <span className="error-message">{formData.email.errorMessage}</span>}
            <input
              type="text"
              className={`form-control ${formData.phone.error ? "error" : ""}`}
              placeholder="Phone Number*"
              value={formData.phone.value}
              onChange={handleInputChange}
              name="phone"
            />
            {formData.phone.error && <span className="error-message">{formData.phone.errorMessage}</span>}
          </div>
          <input
            type="text"
            className={`form-control ${formData.address.error ? "error" : ""}`}
            placeholder="Address*"
            value={formData.address.value}
            onChange={handleInputChange}
            name="address"
          />
          {formData.address.error && <span className="error-message">{formData.address.errorMessage}</span>}
          <div className="flx-input">
            <input
              type="password"
              className={`form-control ${formData.password.error ? "error" : ""}`}
              placeholder="Password*"
              value={formData.password.value}
              onChange={handleInputChange}
              name="password"
            />
            {formData.password.error && <span className="error-message">{formData.password.errorMessage}</span>}
            <input
              type="password"
              className={`form-control ${formData.cpassword.error ? "error" : ""}`}
              placeholder="Confirm Password*"
              value={formData.cpassword.value}
              onChange={handleInputChange}
              name="cpassword"
            />
            {formData.cpassword.error && <span className="error-message">{formData.cpassword.errorMessage}</span>}
          </div>
          <div className="signupbtn">
          <button
          type="submit"
                onClick={handleSubmit}>signup</button>
          </div>
          
        </form>
      </div>
      
      <Button variant="outlined" onClick={handleSubmit}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <form>
        <DialogContent>
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography>{steps[activeStep].label}</Typography>
      </Paper>
      <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
        {steps[activeStep].description}
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
        </DialogContent>
        </form>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    
    </div>
    
  );
};
export default Usersignupform;
