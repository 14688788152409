import React, { useContext, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { MultiStepContext } from '../StepContext';

export default function Questions() {
    const { setStep, userData, setUserData, usersubmitData } = useContext(MultiStepContext);
    const [error, setError] = useState(null);

    const handleSubmit = () => {
        if (!userData.ans1 || !userData.ans2 || !userData.ans3) {
            setError("Please fill in all fields.");
        } else {
            setError(null);
            usersubmitData();
        }
    };

    return (
        <div>
            <h4 style={{textAlign:'center'}}>Security Questions</h4>
            <div className="flx-adrs"> 
            <TextField
                style={{width:'100%'}}
                label="Your Primary School Name"
                margin='normal'
                value={userData['ans1'] || ''}
                onChange={(e) => setUserData({ ...userData, "ans1": e.target.value })}
                variant='outlined'
            />
            </div>
            <div className="flx-adrs"> 
            <TextField
                style={{width:'100%'}}
                label="Your Favourite Food"
                margin='normal'
                value={userData['ans2'] || ''}
                onChange={(e) => setUserData({ ...userData, "ans2": e.target.value })}
                variant='outlined'
            />
            </div>
            <div className="flx-adrs"> 
            <TextField
                style={{width:'100%'}}
                label="Your Best Friend Name"
                margin='normal'
                value={userData['ans3'] || ''}
                onChange={(e) => setUserData({ ...userData, "ans3": e.target.value })}
                variant='outlined'
            />
            </div>
            {error && <p style={{color: 'red'}}>{error}</p>}
            <div className="signupbtnn">  
                <Button variant='contained' onClick={() => setStep(1)} color='secondary'>Back</Button>
                <Button variant='contained' style={{float:'right'}} onClick={handleSubmit} color='primary'>Submit</Button> 
            </div>    
        </div>
    );
}
