import React from 'react';  
import { Link } from 'react-router-dom';
import '../css/header.css';
import MenuIcon from '@mui/icons-material/Menu';
// import { IoMdMenu } from "react-icons/io";
import logo2 from '../images/logo2.png';
  const Header = () => {
    return (
		
		<div className="header">
			<div className='container'>
				<nav className="navbar navbar-expand-lg">
				   <Link to="/" className="navbar-brand"><img src={logo2} alt="Logo" /></Link>
				   <button className="navbar-toggler nvtglr" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
  <span className="navbar-toggler-icon icnsvg">
    {/* Ensure you're correctly rendering the IoMdMenu */}
    <MenuIcon />
  </span>
</button>
					<div className="collapse navbar-collapse" id="navbarNav">
						<ul className="navbar-nav">
						<li className="nav-item active">
						<Link to="/" className="lnks">Home</Link>
						</li>
						<li className="nav-item active">
							<a className="nav-link" href="#">-</a>
						</li>
						<li className="nav-item active">
						<Link to="/Information" className="lnks">Information</Link>
						</li>
						<li className="nav-item active">
							<a className="nav-link" href="#">-</a>
						</li>
	
						<li className="nav-item">
						<Link to="/Login" className="lnks">Login</Link>
						</li>
						<li className="nav-item active">
							<a className="nav-link" href="#">-</a>
						</li>
						<li className="nav-item">
							<Link to="/Register" className="lnks">REGISTER</Link>
						</li>
						</ul>
						
						
					</div>
				</nav>	
			</div>
		</div>	
    );
	

}

export default Header;
