import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Usersignupform from '../components/Usersignupform.js';

const Usersignup = () => {
    return(
        <div>
            <Header />
            <Usersignupform />
            <Footer />
        </div>
    )
}

export default Usersignup;