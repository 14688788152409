import React, { Component } from "react";
import banner from "../images/banner.png";
import "../css/banner.css";
import CurrencyDropdown from "./Currencydropdown";

class Banner extends Component {
  render() {
    return (
      <div className="banner">
        <img src={banner} alt="Logo" />
        <div className="container">
          <div className="banner-text">
            <h3>What are you looking for?</h3>
            <div className="search-slct">
              {/* Category dropdown */}
              <select>
                <option>Social Services</option>
                <option>Dating</option>
                <option>Forum Discussions</option>
                <option>Group Chats</option>
                <option>Jobs Seeking</option>
                <option>Peers to Peers</option>
              </select>

              <div className="crncylist">
                <CurrencyDropdown />
              </div>

              <button>Search</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
