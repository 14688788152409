import React from 'react';
import '../css/signup.css';
import { Link } from 'react-router-dom';
import sclm from '../images/sclm.png';

const Signupp = () =>{
    const backgroundStyle = {
        backgroundImage: `url(${sclm})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '600px', 
        padding: '70px 0',
    };
    return(
        <div className='rgst-bg' style={backgroundStyle}>
            <div className="container">
                <div className="flex-signup">
                    <div className="sgnup-box">
                        <h3>Sign Up</h3>
                        <p>As User</p>
                        <Link to="/Userstepform">SIGN UP</Link>
                    </div>
                    <div className="sgnup-box">
                        <h3>Sign Up</h3>
                        <p>As Vendor</p>
                        <Link to="/Vendorsignup">SIGN UP</Link>
                    </div>
                    <div className="sgnup-box">
                        <h3>Sign Up</h3>
                        <p>AS Affiliate</p>
                        <Link to="/Affiliatesignup">SIGN UP</Link>
                    </div>
                    <div className="sgnup-box">
                        <h3>Sign Up</h3>
                        <p>AS Advertiser</p>
                        <Link to="/Advertisersignup">SIGN UP</Link>
                    </div>
                    <div className="sgnup-box">
                        <h3>Sign Up</h3>
                        <p>AS Instructor</p>
                        <Link to="/Instructorsignup ">SIGN UP</Link>
                    </div>
                    <div className="sgnup-box">
                        <h3>Sign Up</h3>
                        <p>Blog Sign up</p>
                        <Link to="/Blogersignup">SIGN UP</Link>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Signupp;