import React, { Component } from 'react';  
import Grid from '@mui/material/Grid';
import scl from'../images/scl.png';
import scll from'../images/scll.png';

import '../css/social.css';
class Social extends Component {  


    render() {
      return (
        <div className="">
            <div className="container">
                <div className="social-media">
                   
                    
                </div>    
            </div>
        </div>     
      );
      
    }
  }
  







export default Social;