import React, { useContext, useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { MultiStepContext } from '../StepContext';

export default function UserSignupComponent() {
    const { setStep, userData, setUserData } = useContext(MultiStepContext);

    const [fieldErrors, setFieldErrors] = useState({
        firstname: {
            value: "",
            error: false,
            errorMessage: "First Name is required",
        },
        lastname: {
            value: "",
            error: false,
            errorMessage: "Last Name is required",
        },
        email: {
            value: "",
            error: false,
            errorMessage: "Email is required",
        },
        phone: {
            value: "",
            error: false,
            errorMessage: "Phone is required",
        },
        username: {
            value: "",
            error: false,
            errorMessage: "Username is required",
        },
        address: {
            value: "",
            error: false,
            errorMessage: "Address is required",
        },
        password: {
            value: "",
            error: false,
            errorMessage: "Password is required",
        },
        cpassword: {
            value: "",
            error: false,
            errorMessage: "Confirm Password is required",
        }
    });

    function validateForm() {
        let formIsValid = true;

        // Check for empty fields
        Object.keys(fieldErrors).forEach(key => {
            if (!userData[key]) {
                setFieldErrors(prevFieldErrors => ({
                    ...prevFieldErrors,
                    [key]: {
                        ...prevFieldErrors[key],
                        error: true
                    }
                }));
                formIsValid = false;
            }
        });

        // Check password match
        if (userData.password !== userData.cpassword) {
            setFieldErrors(prevFieldErrors => ({
                ...prevFieldErrors,
                cpassword: {
                    ...prevFieldErrors.cpassword,
                    error: true,
                    errorMessage: "Passwords do not match"
                }
            }));
            formIsValid = false;
        }

        if (!/\S+@\S+\.\S+/.test(userData.email)){
            setFieldErrors(prevFieldErrors => ({
              ...prevFieldErrors,
                 email: {
                  ...prevFieldErrors.email,
                     error: true,
                     errorMessage: "Email is not valid"
                 }
            }));
            formIsValid = false;
        }

        return formIsValid;
    }

    function handleNextStep() {
        if (validateForm()) {
            setStep(2);
        }
    }

    function handleChange(event, key) {
        const { value } = event.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            [key]: value
        }));
        setFieldErrors(prevFieldErrors => ({
            ...prevFieldErrors,
            [key]: {
                ...prevFieldErrors[key],
                error: false
            }
        }));
    }
  
    return (
        <div>
            <div className="flx-inputt">
                <div style={{ width: '48%' }}> 
                    <TextField style={{ width: '100%' }} label="First Name" margin='normal' value={userData.firstname} onChange={(e) => handleChange(e, 'firstname')} variant='outlined' error={fieldErrors.firstname.error} /><br />
                    {fieldErrors.firstname.error && <Typography variant="body2" color="error">{fieldErrors.firstname.errorMessage}</Typography>}
                </div>   
                <div style={{ width: '48%' }}> 
                    <TextField style={{ width: '100%' }} label="Last Name" margin='normal' value={userData.lastname} onChange={(e) => handleChange(e, 'lastname')} variant='outlined' error={fieldErrors.lastname.error} /><br />
                    {fieldErrors.lastname.error && <Typography variant="body2" color="error">{fieldErrors.lastname.errorMessage}</Typography>}
                </div>   
            </div>  
            <div className="flx-inputt"> 
                <div style={{ width: '48%' }}> 
                    <TextField style={{ width: '100%' }} label="User Name" margin='normal' value={userData.username} onChange={(e) => handleChange(e, 'username')} variant='outlined' error={fieldErrors.username.error} />
                    {fieldErrors.username.error && <Typography variant="body2" color="error">{fieldErrors.username.errorMessage}</Typography>}
                </div>  
                <div style={{ width: '48%' }}> 
                    <TextField style={{ width: '100%' }} label="Address" margin='normal' value={userData.address} onChange={(e) => handleChange(e, 'address')} variant='outlined' error={fieldErrors.address.error} />
                    {fieldErrors.address.error && <Typography variant="body2" color="error">{fieldErrors.address.errorMessage}</Typography>}
                </div>
            </div>    
            <div className="flx-inputt"> 
                <div style={{ width: '48%' }}> 
                    <TextField style={{ width: '100%' }} label="Email" margin='normal' value={userData.email} onChange={(e) => handleChange(e, 'email')} variant='outlined' error={fieldErrors.email.error} /><br />
                    {fieldErrors.email.error && <Typography variant="body2" color="error">{fieldErrors.email.errorMessage}</Typography>}
                </div>
                <div style={{ width: '48%' }}> 
                    <TextField style={{ width: '100%' }} label="Phone" margin='normal' value={userData.phone} onChange={(e) => handleChange(e, 'phone')} variant='outlined' error={fieldErrors.phone.error} /><br />
                    {fieldErrors.phone.error && <Typography variant="body2" color="error">{fieldErrors.phone.errorMessage}</Typography>}
                </div>
            </div>   
            
            <div className="flx-inputt">    
                <div style={{ width: '48%' }}> 
                    <TextField style={{ width: '100%' }} label="Password" type="password" margin='normal' value={userData.password} onChange={(e) => handleChange(e, 'password')} variant='outlined' error={fieldErrors.password.error} /><br />
                    {fieldErrors.password.error && <Typography variant="body2" color="error">{fieldErrors.password.errorMessage}</Typography>}
                </div>
                <div style={{ width: '48%' }}> 
                    <TextField style={{ width: '100%' }} label="Confirm Password" type="password" margin='normal' value={userData.cpassword} onChange={(e) => handleChange(e, 'cpassword')} variant='outlined' error={fieldErrors.cpassword.error} /><br />
                    {fieldErrors.cpassword.error && <Typography variant="body2" color="error">{fieldErrors.cpassword.errorMessage}</Typography>}
                </div>
            </div> 
            <div className="signupbtn">  
                <Button variant='contained' onClick={handleNextStep} color='primary'>Next</Button>
            </div>     
           
        </div>
    )
}
